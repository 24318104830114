import Vue from "vue";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import axios from "axios";

Vue.mixin({
  methods: {
    formatPrice(value, currency = "đ") {
      if (value !== undefined && value !== null) {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + currency;
      }
      return 0 + currency;
    },
    formatDate(time, format = "D/M/YYYY") {
      if (time !== undefined && time !== null) {
        return dayjs(time)
          .locale("vi")
          .format(format);
      }
      return "";
    },
    exportData(requestUrl, params) {
      try {
        axios({
          url: requestUrl,
          method: "GET",
          responseType: "blob",
          params
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            const filename = response.headers["content-disposition"]
              .split(";")
              .find(n => n.includes("filename="))
              .replace("filename=", "")
              .trim();
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
          })
          .catch(errors => {
            return errors;
            // console.log(errors);
          });
      } catch (e) {
        return e;
        // console.log(e);
      }
    },
    formatDateString(str) {
      let date = new Date(str);
      if (date != "Invalid Date") {
        return this.formatDate(date, 'MM/DD/YYYY');
      }
      let dateArr = str.split('/');
      return `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`
    }
  }
});
